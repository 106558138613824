import React, { useEffect, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { marked } from "marked"
import styled from "styled-components"

import DescriptionRow from "components/common/DescriptionRow"
import urls from "config/urls"
import themeObj from "styles/theme"

const query = graphql`
  query RealizationsQuery {
    allStrapiRealizations {
      nodes {
        name
        slug
        id
        description
        read_more_button_enabled
        order
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

const StyledContent = styled.div`
  display: grid;
  grid-gap: 2rem;
  color: ${({ theme }) => theme.color.text.inverted};
  align-self: flex-end;

  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  strong {
    font-weight: 600;
  }
`

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.color.graphite};
`

const CONTENT_CLASSNAME = "realization_content_element"

const Realizations = () => {
  const { allStrapiRealizations } = useStaticQuery(query)
  const [contentHeights, setContentHeights] = useState({})
  const [expanded, setExpanded] = useState(null)

  const onExpand = id => () => setExpanded(prevId => (prevId === id ? null : id))

  const calculateContentHeights = () => {
    const contentWrapper = [...document.querySelectorAll(`.${CONTENT_CLASSNAME}`)]
    setContentHeights(
      contentWrapper.reduce((accumulator, currentElement) => {
        const key = currentElement.dataset.id
        const { height } = currentElement.getBoundingClientRect()
        accumulator[key] = height
        return accumulator
      }, {})
    )
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", calculateContentHeights)
      setTimeout(calculateContentHeights, 0)
      return () => window.removeEventListener("resize", calculateContentHeights)
    }
  }, [])

  return (
    <StyledWrapper>
      {allStrapiRealizations.nodes
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(({ id, slug, name, description, image, read_more_button_enabled }, index) => (
          <DescriptionRow
            key={id}
            background={themeObj.color.graphite}
            contentClassName={CONTENT_CLASSNAME}
            finalHeight={contentHeights[id]}
            id={id}
            image={image && image.localFile}
            isEven={index % 2}
            isExpanded={id === expanded}
            isReadMore={read_more_button_enabled}
            onExpand={onExpand(id)}
            readMoreUrl={`${urls.portfolioElement.url}${slug}`}
            title={name}
          >
            <StyledContent dangerouslySetInnerHTML={{ __html: marked.parse(description) }} />
          </DescriptionRow>
        ))}
    </StyledWrapper>
  )
}

export default Realizations
