import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledSlide = styled.div`
  height: 120px;
  width: 150px;
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;

  ${({ theme }) => theme.mq.xs} {
    width: 135px;
  }
`

const TrustUsElement = ({ image, name }) => (
  <StyledSlide>
    <GatsbyImage
      alt={name}
      image={getImage(image)}
      imgStyle={{ objectFit: "contain" }}
      style={{ height: "100%", width: "100%" }}
    />
  </StyledSlide>
)

TrustUsElement.propTypes = {
  image: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
}

export default TrustUsElement
