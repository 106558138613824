import React from "react"

import PropTypes from "prop-types"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import TrustUs from "components/common/TrustUs"
import Realizations from "components/pages/realizations/Realizations"

const Portfolio = ({ location }) => (
  <TransitionWrapper location={location}>
    <Seo seoPageName="Portfolio" />
    <SubpageHeader subpageTitle="Portfolio" />
    <Realizations />
    <TrustUs />
  </TransitionWrapper>
)

Portfolio.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Portfolio
