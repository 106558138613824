import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import Carousel from "nuka-carousel"
import { animated, config, useSpring } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

import TrustUsElement from "./_components/TrustUsElement"

const StyledCarousel = styled(Carousel)`
  max-width: 1200px;
  margin: 0 auto;

  .slider-slide {
    opacity: 1;
    transition: transform 0.4s linear 0s, opacity 0.6s !important;
    cursor: default;
    &:focus {
      outline: none !important;
    }
  }
`

const query = graphql`
  query TrustUsQuery {
    strapiCommon {
      trust_us_section {
        section_title
        trust_company {
          id
          link
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`

const TrustUs = () => {
  const { strapiCommon } = useStaticQuery(query)
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })
  const windowWidth = useWindowWidth()

  const numberOfSlides = () => {
    switch (true) {
      case windowWidth <= mq.xs:
        return 2
      case windowWidth <= mq.s:
        return 4
      case windowWidth <= mq.m:
        return 5
      default:
        return 6
    }
  }

  return (
    <Section
      animatedTitle={strapiCommon.trust_us_section.section_title}
      color="gray"
      intersected
      onIntersectChange={setIsVisible}
    >
      <animated.div style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}>
        <Container>
          <StyledCarousel
            autoplay
            cellAlign="left"
            pauseOnHover={false}
            slidesToShow={numberOfSlides()}
            speed={2000}
            withoutControls
            wrapAround
          >
            {strapiCommon.trust_us_section.trust_company.map(({ id, image, name }) => (
              <TrustUsElement key={id} image={image && image.localFile} name={name} />
            ))}
          </StyledCarousel>
        </Container>
      </animated.div>
    </Section>
  )
}

export default TrustUs
