import React, { useState } from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Button from "components/common/Button"
import Section from "components/common/Section"

const colorResolver = (color, theme) => {
  switch (color) {
    case "white": {
      return {
        background: theme.color.white_000,
        text: theme.color.text.default,
      }
    }
    case "cyan": {
      return {
        background: theme.gradient.linear.cyan_dark_to_light,
        text: theme.color.text.inverted,
      }
    }
    default: {
      return {
        background: "transparent",
        text: theme.color.text.inverted,
      }
    }
  }
}

const StyledFlexRow = styled(animated.div)`
  display: flex;
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;
  flex-direction: ${({ isEven }) => (isEven ? "row-reverse" : "row")};

  ${({ theme }) => theme.mq.xs} {
    flex-direction: ${() => "column"};
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.xs} {
    min-height: 256px;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
`

const StyledDescription = styled.div`
  flex-shrink: 2;
  min-height: 600px;
  width: 100%;
  display: flex;
  justify-content: ${({ isEven }) => (isEven ? "flex-end" : "flex-start")};
  background: ${({ theme, color }) => colorResolver(color, theme).background};

  ${({ theme }) => theme.mq.s} {
    min-height: 500px;
  }

  ${({ theme }) => theme.mq.xs} {
    min-height: unset;
  }
`

const StyledContentWrapper = styled.div`
  padding: 5rem;
  width: 540px;
  justify-content: space-between;
  transition: 0.35s;
  height: 100%;

  ${({ theme }) => theme.mq.s} {
    width: 400px;
  }

  ${({ theme }) => theme.mq.xs} {
    width: 100%;
    padding: 0;
    height: ${({ isExpanded, finalHeight }) => (isExpanded ? finalHeight : 0)}px;
    overflow: hidden;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  height: 100%;

  ${({ theme }) => theme.mq.xs} {
    display: block;
  }

  h2 {
    text-align: left;
    color: ${({ theme, color }) => colorResolver(color, theme).text};

    ${({ theme }) => theme.mq.xs} {
      display: none;
    }
  }
`

const StyledText = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-rows: 1fr auto;
  color: ${({ theme, color }) => colorResolver(color, theme).text};

  * {
    color: ${({ theme, color }) => colorResolver(color, theme).text};
  }

  ${({ theme }) => theme.mq.xs} {
    padding: 4rem 2rem;
  }
`

const StyledButton = styled(Button)`
  justify-self: right;
  ${({ theme }) => theme.mq.xs} {
    justify-self: center;
  }
`

const StyledExpandButton = styled.button`
  display: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: ${({ theme }) => theme.color.text.inverted};

  h2 {
    margin: 0;
    font-size: 2rem;
    padding: 2rem;
  }

  ${({ theme }) => theme.mq.xs} {
    display: block;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    right: 2rem;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    transition: 0.35s;
    border-top: ${({ theme }) => `1rem solid ${theme.color.white_000}`};
    transform-origin: 50% 0;
    transform: ${({ isExpanded }) =>
      isExpanded ? `rotate(180deg) translateY(-50%)` : `rotate(0deg) translateY(-50%)`};
  }
`

const DescriptionRow = ({
  id,
  image,
  isEven,
  title,
  children,
  onExpand,
  isExpanded,
  contentClassName,
  finalHeight,
  isReadMore,
  readMoreUrl,
  color,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const offsetX = isEven ? 250 : -250
  const slideSpring = useSpring({ config: config.stiff, x: isVisible ? 0 : offsetX, opacity: isVisible ? 1 : 0 })
  return (
    <Section color="graphite" intersected onIntersectChange={setIsVisible} padding="0">
      <StyledFlexRow
        isEven={isEven}
        style={{
          transform: slideSpring.x.interpolate(dx => `translate3d(${dx}px,0,0)`),
          ...slideSpring,
        }}
      >
        <StyledImageWrapper>
          <StyledGatsbyImage alt={image.alternativeText} image={getImage(image)} imgStyle={{ objectFit: "fixed" }} />
          <StyledExpandButton isExpanded={isExpanded} onClick={onExpand}>
            <h2>{title}</h2>
          </StyledExpandButton>
        </StyledImageWrapper>
        <StyledDescription color={color} isEven={isEven}>
          <StyledContentWrapper finalHeight={finalHeight} isExpanded={isExpanded}>
            <StyledContent color={color}>
              <h2>{title}</h2>
              <StyledText className={contentClassName} color={color} data-id={id}>
                {children}
                {isReadMore && (
                  <StyledButton aria-label={`Read more about ${title}`} to={readMoreUrl}>
                    Read more
                  </StyledButton>
                )}
              </StyledText>
            </StyledContent>
          </StyledContentWrapper>
        </StyledDescription>
      </StyledFlexRow>
    </Section>
  )
}

DescriptionRow.propTypes = {
  image: PropTypes.shape({
    alternativeText: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isEven: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  finalHeight: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  contentClassName: PropTypes.string.isRequired,
  isReadMore: PropTypes.bool.isRequired,
  readMoreUrl: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["white", "cyan"]),
}

DescriptionRow.defaultProps = {
  color: null,
}

export default DescriptionRow
